@tailwind base;
@tailwind components;
@tailwind utilities;

.gradient-text {
  background: linear-gradient(to left, #E43F28, #EB3725, #ED7B5B, #EC8F4C, #E3B97A) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}

.gradient-button {
  text-decoration: none;
  color: white;
  background: linear-gradient(45deg, #E3B97A, #EC8F4C, #ED7B5B, #EB3725, #E43F28);
  transition: background 0.3s ease;
}

.gradient-button-outline {
  text-decoration: none;
  color: #ED7B5B;
  border: 1px solid #E3B97A !important;
}

.gradient-icon {
  text-decoration: none;
  color: #EB3725;
  transition: background 0.3s ease;
}

.gradient-button:hover {
  background: linear-gradient(45deg, #EB3725, #ED7B5B, #E3B97A, #EC8F4C, #E43F28);
  transition: background 0.3s ease;
}

.gradient-text3 {
  background: linear-gradient(to right, #A900B1, #BD00A2, #C80097, #E20183, #FD0170) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}

.gradient-button3 {
  text-decoration: none;
  color: white;
  background: linear-gradient(45deg, #A900B1, #BD00A2, #C80097, #E20183, #FD0170);
  transition: background 0.3s ease;
}

.gradient-button3:hover {
  background: linear-gradient(45deg, #BD00A2, #C80097, #FD0170, #E20183, #A900B1);
}

.gradient-text2 {
  background: linear-gradient(to right, #00E7F5, #00D2FF, #0B81FF, #0D2BFF) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
}

.gradient-button2 {
  text-decoration: none;
  color: white;
  background: linear-gradient(45deg, #00E7F5, #00D2FF, #0B81FF, #0D2BFF);
  transition: background 0.3s ease;
}

.gradient-button2:hover {
  background: linear-gradient(45deg, #00D2FF, #0B81FF, #0D2BFF, #00E7F5);
}